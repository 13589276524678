// full element
.ele-full {
    position: absolute;
    top: 0; left: 0;
    width: 100%; height: 100%;
}

// overlay
.overlay {
    @extend .ele-full;
    background-color: rgba($black, .25);
    @include prefix(transition, .5s);
}

.overlay-hover {
    background-color: rgba($black, .5);
}

// arrow right icon
.arrow-right {
    content: '\f0a9';
    font-family: 'Font Awesome 5 Pro';
    font-weight: 900;
    color: $white;
    font-size: 60px;
    width: 60px;
    height: 60px;
}

// centering elements
.ele-center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}