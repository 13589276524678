// footer
.footer {
    background-color: $white;
    padding-top: 100px;

    .container-holder {
        @include media-breakpoint-down(sm) {
            margin: 0 !important;
        }
    }

    .footer-outro {
        .logo {
            padding-bottom: 30px;

            @include media-breakpoint-down(lg) {
                padding: 0 30px 30px;
            }

            @include media-breakpoint-up(lg) {
                padding: 0 100px 40px;
            }
        }
    }

    .footer-sitelinks {
        .container-holder {
            background-color: $blue-dark;
            border-radius: 30px;
            padding: 30px 15px;

            @include media-breakpoint-down(lg) {
                padding: 50px 30px 0px;
            }

            @include media-breakpoint-up(lg) {
                padding: 70px 100px 40px;
            }

            &>.column {
                &.one {
                    @include media-breakpoint-up(xl) {
                        @include make-col(7)
                    }
                }

                &.two {
                    @include media-breakpoint-up(xl) {
                        @include make-col(5)
                    }
                }

                .footer-text {

                    h1,
                    h2,
                    h3,
                    h4,
                    h5,
                    h6,
                    p {
                        color: $white;
                    }

                    h5 {
                        font-size: 14px;
                    }

                    h2 {
                        font-size: 2.5rem;

                        @include media-breakpoint-up(sm) {
                            font-size: 3rem;
                        }

                        &+p {
                            font-size: 18px;

                            @include media-breakpoint-up(sm) {
                                font-size: 20px;
                            }

                            text-align: justify;
                        }
                    }
                }

                p:has(.icon-box) {
                    margin-bottom: 0;
                }

                .icon-box {
                    padding: 20px 20px;
                    border-radius: 50px;
                    font-weight: bold;
                    text-align: center;
                    text-decoration: none;
                    background-color: $white;
                    color: $blue-dark;
                    font-size: 16px;
                    display: block;
                    max-width: 254px;
                    margin: 0 auto 1rem auto;
                    @include prefix(transition, .5s);

                    &:hover {
                        background-color: lighten($blue-dark, 20%);
                        color: $white;
                        // box-shadow: 3px 3px 20px -6px rgba($black, .6);
                    }


                    @include media-breakpoint-down(lg) {
                        padding: 15px;
                    }

                    i {
                        margin-right: 10px;
                        font-size: 30px;
                        font-weight: 700;
                        vertical-align: middle;
                        font-size: 20px;

                        @include media-breakpoint-down(lg) {
                            margin-right: 10px;
                        }
                    }

                }

                .footer-logolink {
                    .list {
                        margin-top: 1rem;
                        justify-content: center;
                        column-gap: 30px;

                        .list-item {
                            a.link {
                                color: $white;
                                font-size: 30px;
                                display: block;
                                width: 35px;
                                height: 35px;
                                line-height: 35px;
                                text-align: center;
                            }
                        }
                    }
                }
            }
        }
    }

    .footer-copyright {
        .footer-link {
            margin-top: 1rem;

            .list {
                justify-content: center;

                @include media-breakpoint-down(sm) {
                    flex-direction: column;
                    text-align: center;
                }

                .list-item {
                    padding: 0 10px;

                    @include media-breakpoint-up(sm) {
                        &:not(:first-child) {
                            border-left: 1px solid $blue-dark;
                        }
                    }

                    .link {
                        color: $blue-dark;
                        text-decoration: none;
                    }
                }
            }
        }
    }



    .footer-partners {
        .partners-wrapper {
            margin: 40px auto;

            .partners-list {
                @extend .list-unstyled;
                display: flex;
                justify-content: center;
                align-items: center;

                // mobile style
                @include media-breakpoint-down(sm) {
                    display: grid;
                    grid-template-areas: 
                        'col_1_1 col_1_1 col_1_1 col_1_2 col_1_2 col_1_2'
                        'col_2_1 col_2_1 col_2_1 col_2_2 col_2_2 col_2_2'
                        'col_3_1 col_3_1 col_3_2 col_3_2 col_3_3 col_3_3';
                    grid-template-columns: repeat(6, 1fr);
                    grid-gap: 20px 0px;
                }
                .list-item {
                    text-align: center;
                    padding: 0 .5rem;
                    // mobile style
                    @include media-breakpoint-down(sm) {
                        &:nth-child(1){
                            grid-area: col_1_1;
                        }
                        
                        &:nth-child(2) {
                            grid-area: col_1_2;
                        }
    
                        &:nth-child(3) {
                            grid-area: col_2_1;
                        }
                        
                        &:nth-child(4) {
                            grid-area: col_2_2;
                        }
    
                        &:nth-child(5) {
                            grid-area: col_3_1;
                        }
    
                        &:nth-child(6) {
                            grid-area: col_3_2;
                        }
    
                        &:nth-child(7) {
                            grid-area: col_3_3;
                        }
                    }
                    img {
                        @include media-breakpoint-up(sm) {
                            height: 35px;
                            width: auto;
                            max-width: none;

                        }
                        @include media-breakpoint-up(lg) {
                            height: 50px;
                        }
                    }
                }
            }
        }
    }
}