.container-two-columns.full-columns {
    .container-holder {
        margin-left: 0;
        margin-right: 0;
        & > .column {
            padding-right: 0 !important;
            padding-left: 0 !important;
            position: relative;
            padding-top: 50vw;
            aspect-ratio: 1;
            overflow: hidden;
            margin-bottom: 0;
            .resource-image {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                .img-fluid {
                    height: 100%;
                    object-fit: cover;
                    object-position: center;
                    @include prefix(transition, .5s);
                }
                &::after {
                    content: '';
                    @extend .overlay;
                }
            }

            &:hover {
                .resource-image {
                    &::after {
                        @extend .overlay-hover;
                    }
                }
                .img-fluid {
                    transform: scale(1.05);
                }
            }

            .page-block.wysiwyg {
                @extend .ele-full;
                @extend .ele-center;
                *, *:hover {
                    color: $white;
                }
                h3 {
                    font-size: 18px;
                    margin: 0;
                    @include media-breakpoint-down(lg) {
                        font-size: 14px;
                    }
                    
                }
                h2 {
                    margin-bottom: 1rem;
                    font-size: 3rem;
                    @include media-breakpoint-down(lg) {
                        font-size: 2.3rem;
                    }
                }

                i {
                    font-size: 60px !important;
                    @include media-breakpoint-down(lg) {
                        font-size: 40px !important;
                    }
                }
            }
        }
    }
}