// new base file - january 2022

#TommyBookingSupport {
	font-size: 16px;

	h1, h2, h3, h4, h5, h6 {
		font-family: $font-family-primary;
		font-weight: 400;
	}

	h4 {
		font-size: 1.2rem;
	}
}

// colors
#TommyBookingSupport .tbs-navbar-default {
	background: $primary;
	border-radius: 50px;
	border: none;
	margin: 0;
}

#TommyBookingSupport .tbs-navbar-default .tbs-navbar-nav>li>a {
	color: $white;

	&:hover {
		background-color: darken($primary, 20%);
	}
}


#TommyBookingSupport .tbs-navbar-default .tbs-navbar-nav>.tbs-active>a,
#TommyBookingSupport .tbs-navbar-default .tbs-navbar-nav>.tbs-active>a:focus,
#TommyBookingSupport .tbs-navbar-default .tbs-navbar-nav>.tbs-active>a:hover {
	background-color: $secondary;
	color: $white;
}

#TommyBookingSupport .tbs-disabled {
	border-radius: 30px;
}

#TommyBookingSupport .tbs-btn-info {
	background-color: $primary;
	border-color: $primary;
	color: $white;
	border-radius: 30px;
}

#TommyBookingSupport .tbs-btn-info:hover {
	background-color: darken($primary, 20%);
	border-color: darken($primary, 20%);
	color: $white;
}

#TommyBookingSupport .tbs-btn-primary,
#TommyBookingSupport .tbs-btn-success {
	background-color: $secondary;
	border-color: $secondary;
	color: $white;
	border-radius: 30px;
}

#TommyBookingSupport .tbs-btn-primary,
#TommyBookingSupport .tbs-btn-success:hover {
	background-color: darken($secondary, 20%);
	border-color: darken($secondary, 20%);
	color: $white;
}

#TommyBookingSupport .tbs-btn-info,
#TommyBookingSupport .tbs-btn-success {
	transition: 0.5s;
}

#TommyBookingSupport .tbs-calendar .tbs-day.tbs-selected {
	background-color: $primary !important;
}

#TommyBookingSupport .tbs-legenda .tbs-day, 
#TommyBookingSupport .tbs-calendar .tbs-day {
	border-radius: 30px;
}

#TommyBookingSupport .tbs-btn-default {
	border-radius: 30px;
}

// manually include tommy base styling
// source: https://api.tommybookingsupport.com/widgets/zoekenboek/css/default.css
#TommyBookingSupport .tbs-container-fluid {
	padding: 0;
}

#TommyBookingSupport .tbs-navbar-collapse {
	padding: 0;
}

#TommyBookingSupport .tbs-navbar-nav>li>a {
	padding-top: 10px;
	padding-bottom: 10px;
}

#TommyBookingSupport .tbs-navbar {
	min-height: auto;
	overflow: hidden;
}

#TommyBookingSupport * a {
	text-decoration: none;
}

#TommyBookingSupport .tbs-legenda {
	background: none;
	border: none;
}

.tbs_talen {
	.tbs-mg-t-20 {
		margin-top: 0;

		ul {
			margin: 0;
			height: 40px;
			@extend .ele-center;
			flex-direction: row;

			li {
				.tbs-flag {
					width: 20px !important;
					height: 15px !important;
				}
			}
		}
	}
}

#TommyBookingSupport .tbs-row.tbs_persoonscategorien,
#TommyBookingSupport .tbs-row.tbs_accommodaties,
#TommyBookingSupport .tbs-row.tbs_kalenders,
#TommyBookingSupport .tbs-container-fluid.tbs_kassabon,
#TommyBookingSupport .tbs-row.tbs_artikelen,
#TommyBookingSupport .tbs-row.tbs_persoon,
#TommyBookingSupport .tbs-row .tbs-page-overzicht>.tbs-container-fluid>.tbs-row:nth-child(2) {
	padding: 20px 0;
	margin: 20px 0;
	background-color: $white; // custom
	border: 1px solid $white; // custom
	border-radius: 16px;
}

#TommyBookingSupport .tbs-container-fluid.tbs_kassabon {
	padding: 20px;
}

#TommyBookingSupport .tbs_overlay-mask {
	border-radius: 5px;
}

#TommyBookingSupport .tbs-calendar {
	margin: 20px 0;
}

#TommyBookingSupport .tbs-calendar .tbs-day.tbs-beschikbaar {
	cursor: pointer;
	transition: all 0.5s ease;
}

#TommyBookingSupport .tbs-calendar .tbs-day.tbs-beschikbaar:hover {
	opacity: 0.8;
}

#TommyBookingSupport .tbs-calendar table>thead>tr>th {
	border: none;
}

#TommyBookingSupport .tbs-calendar .tbs-day.tbs-selected {
	color: #fff;
	background-color: #105251;
}

#TommyBookingSupport .tbs-row h2 {
	margin: 1rem 0 0;
	font-size: 24px;
}

#TommyBookingSupport .tbs-btn-block+.tbs-btn-block {
	margin: 0;
}

#TommyBookingSupport .tbs_overzicht .tbs-row h2 {
	margin-bottom: 15px;
}

#TommyBookingSupport .tbs_overzicht .tbs-specificaties .tbs-row {
	margin-top: 15px;
}

#TommyBookingSupport .tbs-alert-danger {
	color: #E91E63;
	background-color: rgba(233, 30, 99, 0.09);
	border: none;
	margin: 0 15px 15px;
	padding: 10px 35px 10px 10px;
}

#TommyBookingSupport .tbs-has-error .tbs-form-control {
	border-color: rgb(244, 67, 54);
}

/* kassabon */
#TommyBookingSupport .tbs_kassabon .tbs-row {
	margin-bottom: 0;
}

#TommyBookingSupport .tbs-label {
	font-size: 100%;
	margin-right: 5px;
}

#TommyBookingSupport .tbs_kassabon .tbs-hr {
	border-top: 1px solid #e7e7e7;
}

#TommyBookingSupport .tbs_kassabon .tbs-totaal span.tbs-translate {
	font-weight: 400;
}

#TommyBookingSupport .tbs_kassabon .tbs_totaal_overzicht .tbs-totaal {
	background: unset;
	color: $black;

	.tbs-amount,
	.tbs_kassabon_toggle_show,
	.tbs_kassabon_toggle_hide {
		color: $secondary;
	}

}

#TommyBookingSupport .tbs_kassabon_toggle {
	display: inline-block !important;
	@include prefix(transform, rotate(90deg));
	margin-left: 10px;
}

#TommyBookingSupport .tbs_kassabon .tbs-totaal span#totaal_amount {
	font-size: 32px;
	line-height: 50px;
}

#TommyBookingSupport .tbs_persoonsgegevens_overzicht>.tbs-row {
	margin: 0 0 15px 0;
}

/* resonsive mobile */
@media (max-width: 767px) {

	#TommyBookingSupport .tbs-calendarPrevious span.tbs-translate,
	#TommyBookingSupport .tbs-calendarNext span.tbs-translate {
		display: none;
	}

}

#TommyBookingSupport .tbs-form-control {
	border-radius: 25px;
}

#TommyBookingSupport .tbs-widget-label {
	display: none;
}