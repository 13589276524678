// custom-list
.custom-list {
	padding: 0;
	list-style: none;

	li {
		position: relative;
		padding: 0 0 0 1.5rem;

		&::before {
			content: '\f00c';
			position: absolute;
			top: 0;
			left: 0;
			font-family: $font-awesome;
			color: $primary;
			font-weight: 900;
		}
	}
}

// custom-list-extra
.custom-list-extra {
	padding: 0;
	list-style: none;

	li {
		position: relative;
		padding: 0 0 0 1.5rem;

		&::before {
			content: '\f058';
			position: absolute;
			top: 0;
			left: 0;
			font-family: $font-awesome;
			color: $primary;
			font-weight: 400;
		}
	}
}

// icon-list
.icon-list {
	.grid-items {
		flex-direction: column;
		.item {
			flex: 0 0 100% !important;
			max-width: 100% !important;
			margin: 0 0 50px 0 !important;

			&:last-child {
				margin: 0;
			}

			.card {
				flex-direction: row;
				align-items: center;
				border: unset;
				background-color: unset;

				.card-image {
					padding-top: 0;
					background-color: $blue-dark;
					color: $white;
					width: 70px;
					height: 70px;
					border-radius: 50%;

					i {
						font-size: 36px;
						line-height: 70px;
					}
				}
				.card-body {
					padding: 0 15px;
					.card-title {
						font-size: 24px;
						text-transform: uppercase;
						font-family: $font-family-secondary;
						margin-bottom: 3px;
						&:hover {
							color: $black;
						}
					}
					.card-description {
						p {
							margin: 0;
						}
					}

					.card-buttons {
						display: none;
					}
				}
			}
		}
	}
}