// eyecatcher
.eyecatcher,
.eyecatcher .owl-carousel .item {
	height: 410px;
	min-height: 50vh;
	max-height: 100vh;
	align-items: flex-end;

	.owl-caption {
		.owl-title {
			font-size: 18px;
		}
		.owl-subtitle {
			font-size: clamp(2rem, 2.5vw, 3rem);
			margin-bottom: 3rem;
		}
	}
}

.eyecatcher.large,
.eyecatcher.large .owl-carousel .item {
	height: 660px;
	min-height: 300px;
	max-height: 700px;

	@include media-breakpoint-down(sm) {
		height: 410px;
	}
}

// contact page
&.contact-page {
	.eyecatcher,
	.eyecatcher .owl-carousel .item {
		.owl-caption {
			.owl-subtitle {
				margin-bottom: 4.5rem;
			}
		}
	}
}