&.contact-page {
    .form {
        border: none;
        padding: 0;
        form {
            .form-control {
                border-radius: 20px;
    
            }
            textarea {
                height: 150px;
            }
        }
    }
}