// header
.header {
	z-index: 998;
	position: sticky;
	top: 0;
	left: 0;
	right: 0;
	background: $white;
	transition: 0.5s;

	&.sticky {
		box-shadow: $shadow;
		position: fixed;

		.logo {
			max-width: 180px;
			margin: 10px;
		}
	}

	.container,
	.container-fluid {
		.container-holder {
			>.column {
				align-items: center;

				@include media-breakpoint-down(lg) {
					flex-flow: row wrap;
				}

				@extend .navbar-expand-xl;
			}
		}
	}

	// menu-toggle
	.menu-toggle {
		@include media-breakpoint-down(lg) {
			order: 1;
		}
		.navbar-toggler {
			padding: 0.25rem 5px;
		}
	}

	// logo
	.logo {
		width: auto;
		margin: 15px 10px;
		@include prefix(transition, .5s);
		@include media-breakpoint-down(lg) {
			max-width: 190px;
			order: 1;
		}
		max-width: 100%;

		a {
			@extend .d-block;

			img {
				@extend .w-100;
			}
		}
	}

	// menu
	.menu {
		justify-content: flex-end;
		@include media-breakpoint-down(lg) {
			order: 10;
			padding: 10px 0;
		}
		.navbar-nav {
			.nav-item {
				.nav-link {
					color: $black;
					font-weight: bold;
					font-size: 18px;
				}

				&:hover,
				&.active {
					.nav-link {
						color: $blue-dark;
					}	
				}
			}
		}
		// @include media-breakpoint-down(lg) {}

		// @include media-breakpoint-up(xl) {}
	}

	// Zoek en book
	div.book-button {
		@include media-breakpoint-down(lg) {
			position: absolute;
			// bottom: -12px;
			top: 4rem;
			right: 15px;
		}

		ul.book-button {
			margin: 0 20px;
			@include media-breakpoint-down(lg) {
				margin: 0
			}
			li {
				@include prefix(transition, .5s);
				a {
					text-decoration: none;
					color: $white;
					background-color: $blue-dark;
					padding: 0.5rem 2rem;
					border-radius: 50px;
					font-size: 18px;
					font-weight: bold;
					@include prefix(transition, .5s);
					
					@include media-breakpoint-down(sm) {
						font-size: 14px;
						padding: .5rem 1rem;
					}

					&:hover {
						background-color: darken($blue-dark, 20%);
					}
				}
				&:hover {
					transform: scale(1.05);
				}
			}
		}
	}

	// site-switcher
	.site-switcher {
		margin: 15px 10px 15px 0;
		@include media-breakpoint-down(lg) {
			margin: 15px 5px 15px auto;
			order: 1
		}
		.navbar-nav.language {
			@extend .list-unstyled;
			display: flex;
			align-items: center;
			.nav-item {
				font-weight: 100;

				&.active {
					font-weight: 500;
				}
				.nav-link {
					color: $black;
					border-radius: unset;
					border: none;
					font-size: 14px;
					&:hover {
						box-shadow: none;
						border-color: unset;
					}
				}
			}

		}
	}
}
