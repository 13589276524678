.card {
    border: unset;
    background-color: unset;

    .card-image {
        border-radius: 10px;
        padding-top: 100%;
        aspect-ratio: 1;

        .card-img {
            @extend .ele-full;
            object-fit: cover;
        }
    }

    .card-body {
        padding: 30px 0;

        .card-caption {
            .card-title {
                font-size: 24px;
            }

            .card-subtitle {
                font-size: 12px;
                color: $secondary;
                display: none;
            }

            .card-description {
                .card-description-content {
                    display: -webkit-box;
                    -webkit-line-clamp: 7;
                    line-clamp: 7;
                    -webkit-box-orient: vertical;
                    overflow: hidden;

                }
            }
        }

        .card-buttons {
            display: none;
        }
    }
}