// new base file - january 2022

// override source styling: https://api.tommybookingsupport.com/widgets/zoekenboek/css/default.css
.search-book {
    @extend .clearfix;

    .tommy-zeb-wrapper {
        .tbs-widget-label {
            margin: 0;

            .tbs-widget-label-span {
                display: none;
            }
        }

        * {
            font-family: $font-family-base !important;

            h2 {
                font-size: 18px;
                font-weight: 700;
            }
        }

        .tommy-zeb-left {
            background: $blue-dark;
            border-radius: 16px;

            h1 {
                display: none;
            }

            select,
            input {
                border-radius: 20px;
            }

            .tommy-zeb-person-categories {
                h2 {
                    margin-top: 0 !important;
                }
            }
        }

        .tommy-zeb-right {

            .tommy-zeb-results {
                .tommy-zeb-results-label {
                    margin-bottom: 1rem;
                }

                .tommy-zeb-result {
                    &.ng-scope {
                        // background: rgba($blue-dark, 0.1);
                        background: transparent;
                        padding: 0;
                        border-radius: 16px;

                        @include media-breakpoint-up(lg) {
                            display: flex;
                        }
                    }

                    .tommy-zeb-result-image {
                        flex: 1 1 100%;
                        max-width: 250px;
                        overflow: hidden;
                        border-radius: 10px;

                        @include media-breakpoint-down(lg) {
                            margin: 0 0 15px;
                        }
                        @include media-breakpoint-down(sm) {
                            max-width: 100%;
                        }

                        img {
                            aspect-ratio: 1;
                            object-fit: cover;
                            @include prefix(transition, .5s);

                            &:hover {
                                @include prefix(transform, scale(1.05));
                            }
                        }


                    }

                    .tommy-zeb-result-details {
                        display: flex;
                        flex-direction: column;
                        padding: 0;

                        @include media-breakpoint-up(xl) {
                            max-width: 520px;
                            flex: 1 1 100%;
                            padding: 0 20px;
                        }

                        .tommy-zeb-result-label.ng-binding {
                            margin-bottom: 10px;
                            color: $blue-dark;
                            font-size: 20px;
                            font-weight: 700;
                        }

                        .tommy-zeb-result-actions {
                            margin-top: auto;
                        }

                        .tommy-zeb-result-price.ng-binding {
                            color: darken($secondary, 20%);
                            font-weight: 700;
                        }


                        .tommy-zeb-btn {
                            @extend .btn;
                            @extend .btn-secondary;
                        }
                    }
                }
            }
        }

    }
}

&.accommodation-search-book.bundle-overview {
    .global-banner {
        margin-top: 0;
    }
}


// calendar
.pika-single .is-today .pika-button {
    color: $primary;
    &:hover {
        color: $white;
    }
}
.pika-single .pika-button {
    &:hover {
        background-color: $secondary;
    }
}