// body bg
& {
	background: url('/images/wave.svg') $blue repeat center center;
	overflow-x: clip;
}

// container
.container {
	&.container-small {
		max-width: 540px;
	}

	&.container-medium {
		max-width: 750px;
	}

	&.container-large {
		max-width: 1290px;
	}

	&.container-intro {
		max-width: 720px;
		margin-bottom: 30px;
		text-align: center;
	}

	&.container-outro {
		max-width: 720px;
		margin-top: 30px;
		text-align: center;
	}
}

.container-two-columns .container-holder { margin-bottom: 0; }

// img & iframe
img {
	max-width: 100%;
	height: auto;
}

// links
a, a:hover {
	text-decoration: none;
}

iframe {
	max-width: 100%;
}

// form
.form {
	padding: 15px;

	@include media-breakpoint-up(xl) {
		padding: 30px;
	}

	border: 1px solid #ced4da;
	border-radius: 0.25rem;
}

.page-block.wysiwyg {
	i {
		margin-right: 10px;
		font-size: 20px !important;
	}
}
