&.brasserie-page {
    //body 
    background: $brasserie_bg;

    // header
    .header {
        background-color: $brasserie_bg;

        .logo {
            padding: 1rem;
            background-color: $white;
            border-radius: 50%;
            position: absolute;
            top: 0;
            left: 1rem;

            // logo
            @include media-breakpoint-down(lg) {
                max-width: 130px;
                left: 50%;
                padding: .65rem;
                @include prefix(transform, translateX(-60%));
            }
        }

        .menu {
            .navbar-nav {
                .nav-item {
                    .nav-link {
                        padding-top: 40px;
                        padding-bottom: 40px;
                        @include media-breakpoint-down(lg) {
                            padding-top: 0.5rem;
                            padding-bottom: 0.5rem;
                        }
                    }
                }
            }
        }
    }
}