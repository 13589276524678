// Global Banner
.global-banner {
	margin-top: 100px;
	.owl-carousel:not(.eyecatcher-owl-carousel) {
		.item {
			height: 410px !important;
			min-height: 50vh;
			max-height: 100vh;
		
			@include media-breakpoint-up(md) {
				height: 690px !important;
			}

			&::before {
				content: '';
				@extend .overlay;
			}
			&:hover {
				&::before {
					@extend .overlay-hover;
				}
			}

			.owl-caption-holder {
				z-index: 10;

				.owl-caption {
					.owl-title {
						font-size: 18px;
					}
					.owl-subtitle {
						font-size: clamp(2rem, 2.5vw, 3rem);
					}

					.owl-description {
						color: $white;
						a {
							color: $white;
						}

						i {
							font-size: 60px;

							@include media-breakpoint-down(lg) {
								font-size: 40px;
							}
						}
					}
				}
			}
		}
	}
}