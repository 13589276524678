// reset body .collection.grid .grid-items
.collection.grid {
	.grid-items {
		margin: 0 -15px;
		justify-content: flex-start;
		.item {
			@include media-breakpoint-up(xl) {
				flex: 0 0 25%;
				max-width: 25%;
			}
		}
	}
}

// Services Box - Homepage
.collection.grid {
	&.services-wrapper {
		.grid-items {
			.item {
				@include media-breakpoint-down(md) {
					&:nth-last-child(-n +2) {
						margin-bottom: 0;
					}
				}

				@include media-breakpoint-down(sm) {
					&:last-child {
						margin-bottom: 0;
					}

					&:nth-last-child(2) {
						margin-bottom: 30px;
					}
				}
				@include media-breakpoint-up(xl) {
					flex: 0 0 33.33333%;
					max-width: 33.33333%;
				}

				.card {
					border-radius: 10px;
					.card-body {
						@extend .overlay;

						.card-caption {
							display: flex;
							justify-content: center;
							align-items: center;
							flex-direction: column-reverse;
							color: $white;
							text-transform: uppercase;

							.card-title {
								font-size: 30px;
								font-family: $font-family-secondary;
								margin: 0;

								&:hover {
									color: $white;
								}
							}

							.card-subtitle {
								font-size: 16px;
								font-family: $font-family-tertiary;
								margin: 0;
							}
						}
					}

					&:hover {
						.card-image {
							.card-img {
								transform: scale(1.05);
							}

						}

						.card-body {
							@extend .overlay-hover;
						}
					}
				}
			}
		}
	}
}
