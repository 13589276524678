// mini-sab
.eyecatcher+.mini-sab {
	.container {
		position: relative;
		@extend .d-flex;
		@extend .justify-content-center;

		.container-holder {
			height: calc(320px - 4rem);

			@include media-breakpoint-up(md) {
				height: calc(200px - 4rem);
			}

			@include media-breakpoint-up(lg) {
				height: 0;
			}

			.mini-search-book {
				top: -4rem;
				margin-top: 0;

				// below eyecatcher
				@include media-breakpoint-up(lg) {
					top: calc(-96px - 2rem);
				}
			}
		}
	}
}

// contact page
&.contact-page {
	.eyecatcher+.mini-sab {
		.container { 
			.container-holder {
				.mini-search-book {
					top: -50px;
				}
			}
		}
	}
}

.mini-sab {
	.container {
		.container-holder {
			.mini-search-book {
				z-index: 100;
				position: relative;
				margin: 0;
				padding: 30px 20px 40px;
				border-radius: 30px;
				background: $blue-dark;
				box-shadow: $shadow;
				color: #fff;
				margin-top: 2rem;

				// below eyecatcher
				@include media-breakpoint-up(lg) {
					// top: calc(-96px - 2rem);
					padding: 15px 30px;
					border-radius: 50px;
				}
			}
		}
	}
}