// btn
.btn {
	box-shadow: $shadow;
	transition: .5s;
	margin-right: 10px;
	margin-bottom: 10px;
	&:hover {
		transform: scale(1.05);
	}

	// btn-primary
	&.btn-primary {
		border-radius: 50px;
		padding: .5rem 2rem;
		line-height: 1;
		// &:hover {}
	}

	// btn-outline-primary
	// &.btn-outline-primary {
	// 	&:hover {}
	// }

	// btn-secondary
	&.btn-secondary {
		border-radius: 50px;
		padding: .5rem 2rem;
		line-height: 1;
		// &:hover {}
	}

	// btn-outline-secondary
	// &.btn-outline-secondary {
	// 	&:hover {}
	// }
}

// card-btn
.card-btn {
	@extend .btn;
	@extend .btn-primary;
}

// btn-back
.btn-back {
	position: relative;
	display: inline-flex;
	align-items: center;
	padding-left: 1rem;
	margin-bottom: 0 !important;

	&::before {
		content: "\f0d9";
		position: absolute;
		left: 0;
		font-weight: 900;
		font-family: $font-awesome;
	}
}

// btn-down
.btn-down {
	@extend .btn;
	@extend .btn-primary;
	margin-bottom: 0;
}
