// bg-*
// =========================================================================
section {

	&.bg-light,
	&.bg-dark {
		margin: 0;
		padding: 50px 0;
	}
}

// .lead-section+.content-section,
// .lead-section+.bundle-overview-section {
// 	margin-top: calc(-6vh + 30px);
// }

// lead-section
// =========================================================================
.lead-section {
	padding: 50px 0 0;

	@include media-breakpoint-up(lg) {
		padding: 100px 0 50px;
	}

	.container-one-column {
		.container-holder {
			text-align: center;
		}
	}

	.container-two-columns {
		h1 {
			font-size: 2.3rem;

			@include media-breakpoint-up(lg) {
				font-size: 3rem;
			}
		}

		h5 {
			font-size: 14px;
			font-family: $font-family-tertiary;
			// @include media-breakpoint-up(lg) {
			// 	font-size: 3rem;
			// }
		}
	}

	// bundles
	.info-nav {
		@extend .d-flex;
		@extend .flex-wrap;
		@extend .align-items-center;
		@extend .justify-content-between;
		margin-bottom: 30px;
	}

	.info {

		// accommodation
		.properties {
			@extend .d-flex;
			@extend .flex-wrap;
			@extend .justify-content-center;
			@extend .list-unstyled;

			li {
				margin: 0 15px;
			}

			.icon {
				margin-right: 5px;
			}
		}


		// assortiment
		.former-price {
			font-size: $font-size-sm;
			text-decoration: line-through;
		}

		.discount-price {
			font-size: $font-size-sm;

			.amount {
				font-size: $font-size-lg;
			}

			margin-bottom: 1rem;
		}

		.price {
			margin-bottom: 1rem;

			.amount {
				font-size: $font-size-lg;
			}
		}

	}
}

// content-section
// =========================================================================
.content-section {
	padding: 50px 0;

	&:not(:has(.full-columns)) {
		.resource-image {
			position: relative;
			padding-top: 100%;
			aspect-ratio: 1;
			border-radius: 10px;
			overflow: hidden;

			img {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				aspect-ratio: 1;
				object-fit: cover;
			}
		}
	}
}

// banner-section
// =========================================================================
// .banner-section {}

// bundle-overview-section
// =========================================================================
.bundle-overview-section {
	padding: 50px 0;

	// collection
	.collection {
		.card {
			@extend .default-card !optional;
		}
	}
}

// bundle-detail-section
// =========================================================================
.bundle-detail-section {
	padding: 50px 0;
}

// gallery-section
// =========================================================================
.gallery-section {
	.gallery-img {
		border-radius: 20px;
	}
}

// booking-section
// =========================================================================
.booking-section {
	padding: 50px 0;

	h2 {
		margin-bottom: 30px;
	}
}

// contact-section
// =========================================================================
.contact-section {
	padding: 50px 0;
}

.impression-section {
	padding: 50px 0;
}

&.service-detail .content-section a {
	font-size: 18px;
	font-weight: bold;
}